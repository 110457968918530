import { exp } from 'mathjs';
import cityUpgrades from '../config/cityUpgrades';

export default function calculateMaxPopulation({ city, blockCount }) {
  let maxPopulation = 0;

  const rating = city.rarityScore;

  if (typeof blockCount !== 'number') {
    blockCount = city.metadata?.properties?.['Block Count'] || 0;
  }

  if (blockCount === 0) {
    maxPopulation = 0;
  } else if (blockCount === 9) {
    maxPopulation = 20;
  } else if (blockCount === 8) {
    maxPopulation = 15;
  } else if (blockCount >= 6 && blockCount <= 7) {
    maxPopulation = 10;
  } else if (blockCount >= 3 && blockCount <= 5) {
    maxPopulation = 5;
  } else if (blockCount >= 1 && blockCount <= 2) {
    maxPopulation = 2;
  }

  if (city.metadata?.properties?.['Upgrade']) {
    const theUpgradeName = city.metadata?.properties?.['Upgrade'];
    const theUpgrade = cityUpgrades.find((upgrade) => upgrade.name === theUpgradeName);
    const requirements = theUpgrade.requirements;
    const metadata = city.metadata.properties;

    if (requirements) {
      const hasCorrectBlocks = requirements.blocks.filter((block) =>
        Object.values(metadata).some((meta) => typeof meta === 'string' && meta.includes(block))
      );
      const hasEnoughBlocks = Object.values(metadata).filter((meta) => typeof meta === 'string' && meta.includes(' - ')).length === 9;
      const hasEnoughCitizens = (city?.citizens ?? []).length >= 26;

      if (hasEnoughCitizens && hasEnoughBlocks && hasCorrectBlocks.length > 0) {
        maxPopulation += theUpgrade.populationIncrease;
      } else {
        maxPopulation -= theUpgrade.populationIncrease;
      }
    } else if (theUpgrade.populationIncrease) {
      // if no requirements, just add the population increase
      maxPopulation += theUpgrade.populationIncrease;
    }
  }

  // add modifier based on rating
  if (rating > 0) {
    // Calculate modifier based on rating using sigmoid function
    const modifier = 1 / (1 + exp(-0.1 * (rating - 50)));
    const upperLimit = 1.5;
    const scaledModifier = 1 + (upperLimit - 1) * modifier;
    const bonus = (blockCount * scaledModifier) / 2;
    maxPopulation = Math.floor(maxPopulation + bonus);
  }

  return maxPopulation;
}
