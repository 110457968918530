import akitaAssets from '../data/akita';
import alexAssets from '../data/alex';
import blopAssets from '../data/blops';
import bobAssets from '../data/bob';
import borksAssets from '../data/borks';
import clonesAssets from '../data/clones';
import coffeeAssets from '../data/coffee';
import crazyAssets from '../data/crazy';
import cyberAssets from '../data/cyber';
import darumaAssets from '../data/daruma';
import depthfolkAssets from '../data/depthfolk';
import dragonAssets from '../data/dragons';
import giantsAssets from '../data/giants';
import goannaAssets from '../data/goanna';
import goannaMutantsAssets from '../data/goanna-mutants';
import goannaZombiesAssets from '../data/goanna-zombies';
import gootAssets from '../data/goots';
import hansAssets from '../data/hans';
import heepowAssets from '../data/heepow';
import joejoBabbitAssets from '../data/joe-jo';
import knitheadsAssets from '../data/knitheads';
import littleRoyalsAssets from '../data/little-royals';
import lundiAssets from '../data/lundis';
import mentalMarvinsAssets from '../data/mental-marvins';
import meowlgoAssets from '../data/meowlgo';
import mngoAssets from '../data/mngo';
import monstiAssets from '../data/monsti';
import mortalityAssets from '../data/mortality';
import mostlyFrensAssets from '../data/mostly-frens';
import nootsAssets from '../data/noots';
import ravenAssets from '../data/raven';
import robotAssets from '../data/robots';
import seafolkAssets from '../data/seafolk';
import seagodsAssets from '../data/seagods';
import shepAssets from '../data/shep';
import shibaAssets from '../data/shiba';
import skuliAssets from '../data/skuli';
import spectacularAssets from '../data/spectacular';
import statePooofAssets from '../data/state-pooofs';
import wizardAssets from '../data/wizards';
import wormAssets from '../data/worms';
import yieldlingAssets from '../data/yieldlings';
import gullyAssets from '../data/gully';
import billyAssets from '../data/billy';

const collabBlocks = {
  mngo: {
    id: 0,
    isActive: false,
    stakingOpensOn: '2023-06-25T18:00:00.000Z',
    collection: 'M.N.G.O/Mostly Frens',
    blockId: 1139438477,
    blockName: 'Mostly Dreamverse',
    blockImage: '/collab-blocks/mostly.png',
    addresses: [
      'MNGOLDXO723TDRM6527G7OZ2N7JLNGCIH6U2R4MOCPPLONE3ZATOBN7OQM',
      'MNGORTG4A3SLQXVRICQXOSGQ7CPXUPMHZT3FJZBIZHRYAQCYMEW6VORBIA',
      'MNGOZ3JAS3C4QTGDQ5NVABUEZIIF4GAZY52L3EZE7BQIBFTZCNLQPXHRHE',
      'MNGO4JTLBN64PJLWTQZYHDMF2UBHGJGW5L7TXDVTJV7JGVD5AE4Y3HTEZM',
      'MOSTLYSNUJP7PG6Q3FNJCGGENQXMOH3PXXMIJRFLODLG2DNDBHI7QHJSOE',
      '5DYIZMX7N4SAB44HLVRUGLYBPSN4UMPDZVTX7V73AIRMJQA3LKTENTLFZ4',
    ],
    assets: [...mngoAssets, ...mostlyFrensAssets, ...yieldlingAssets],
  },
  statePooofs: {
    id: 1,
    isActive: false,
    stakingOpensOn: '2023-07-06T18:00:00.000Z',
    collection: 'Pooof/Lundi/Monstii',
    blockId: 1149446294,
    blockName: 'Lundi Beach Club', // todo change this
    blockImage: '/collab-blocks/lundi.png',
    addresses: [
      'UB6E3ACF7MHSAY5LRRNUHMW55IAQGGWOVADTVDNMF7JL7CA25GO7V4F2MI',
      'PFDZQWMRT2KTJTB3VUGDOILNCNSB63ILZL4XMBHYECBOV24LGTID4YRFPM',
      '7PVEEP2CS77VJEYZGW2IIGZ63P5CO557XRNKBRPTIREKLET7A4G62W4CQA',
      'TXZ3AKZLIKNNT3OBQOMTSYWC7AK7CIVSZZIDCSTONXNTX44LQIRU6ELFDA',
      'KEFVKQAEKQBFHS5UJMC3JL7OSODFIEBYRAYXDXL5UL4WEBHSVMB2EBITP4',
      'ONYCV746UXUU337323KNMNCAMZ5YLC6U5KLTOAFD5AVDDRMYVDOJHXMSVQ',
    ],
    assets: [...statePooofAssets, ...lundiAssets, ...monstiAssets],
  },
  goots: {
    id: 2,
    isActive: false,
    stakingOpensOn: '2023-07-20T18:00:00.000Z',
    collection: 'CGF/CGR',
    blockId: 1158780700,
    blockName: 'Golden Goot Temple',
    blockImage: '/collab-blocks/goots.png',
    addresses: [
      'GOOSEKPIKOZPEPBMFO7TRRR2EPXLWKOIBLKXJKXWMK2J56SOXWRC3FLNSU',
      'GOOSEOQSO2XM54KNCN2ESH3A7VCHRFSCFMACE24QLBUGCR256JOGRCYSSI',
      'GOOSE4NW53JZLCG6NX37WWPUAOOYSAHPVPPEMBI7FGZZBZ4OET27JV4O3U',
      'GOOSE7PN4S366W5LLQ3TRO4BCB2C66VBSMMXRVAWAPGZJHJYR34VNK2AU4',
      'GOOSECHXVEKJ4SO43NTW5HXOIGLFGC2SQDAVWQGJCN576ODJ5SECV6MUOM',
      'ROBOTEKTU645GDM42JVHV6MIOM2YOGF4JK2YRPFXNG7XLJWPCBPBBH7WOI',
    ],
    assets: [...gootAssets, ...robotAssets],
  },
  joejos: {
    id: 3,
    isActive: false,
    stakingOpensOn: '2023-07-30T18:00:00.000Z',
    collection: 'JoeJoBabbit/JoeJo8Bit',
    blockId: 1164204874,
    blockName: 'King Carrot',
    blockImage: '/collab-blocks/king-carrot.png',
    addresses: ['A5LY5KBYK3TOGZTDVQMPRVWBP3QBTH646VPCJ4JTPEWF7AGLSDJCTOCN3Q', 'XX4JZTERT4IT56M45IO2AISMECCJZAOF25SQV4CLA6NWY42VIIBF3IKC4Y'],
    assets: [...joejoBabbitAssets],
  },
  seafolk: {
    id: 4,
    isActive: false,
    stakingOpensOn: '2023-08-06T18:00:00.000Z',
    collection: 'SeaFolk/SeaGods/DepthFolk',
    blockId: 1169314818,
    blockName: 'SeaFolk Block',
    blockImage: '/collab-blocks/seafolk.png',
    addresses: [
      'I36V2745GNHNEE6CXZSAB76DCRTFHN7RLBRFWVZ6DAXUF6PFSMHZDQK57I',
      'YRBKG74MVJUVFR7BKOMA4YNCAG4L3BJVOVIP7QMDPOVOJBGCLXZHSJPGRY',
      'HKTWNMMO3PV67VHRCW2GNKS5NKACNBQE2SW42BCTKWHJ3HO7E5XDNLBNPA',
    ],
    assets: [...seafolkAssets, ...seagodsAssets, ...depthfolkAssets],
  },
  flemmies: {
    id: 5,
    isActive: false,
    stakingOpensOn: '2023-08-28T18:00:00.000Z',
    collection: 'Flemish Giants/Clones',
    blockId: 1184888784,
    blockName: 'Splicing Facility',
    blockImage: '/collab-blocks/splicing-facility.png',
    addresses: ['PIIQQI6WFNT3S6VEMNTQZNWLDUYXUEWRLZLTBWMJABEGPUZXML2Y5SKC6A', 'RDA5RAUX4N2SJ7KLXKFYBDFGBDZLB4SW3MBU3IQG5VCUIBASFID44NFH5I'],
    assets: [...giantsAssets, ...clonesAssets],
  },
  shep: {
    id: 6,
    isActive: false,
    stakingOpensOn: '2023-09-04T18:00:00.000Z',
    collection: 'Shep',
    blockId: 1189235881,
    blockName: 'MEEP Olympics',
    blockImage: '/collab-blocks/shep.png',
    addresses: ['SHEPWD4POJMJ65XPSGUCJ4GI2SGDJNDX2C2IXI24EK5KXTOV5T237ULUCU'],
    assets: [...shepAssets],
  },
  spectacular: {
    id: 7,
    isActive: false,
    stakingOpensOn: '2023-09-04T18:00:00.000Z',
    collection: 'Algo Spectacular Cats',
    blockId: 1189236473,
    blockName: 'Spectacular Villa',
    blockImage: '/collab-blocks/spectacular.png',
    addresses: ['SNUAAVKRAB7S3LRZDDJOOW77U6UVZU333BCBKWF6F6SQAF5IPVWEJKCXHI'],
    assets: [...spectacularAssets],
  },
  mortality: {
    id: 'L1',
    isActive: false,
    stakingOpensOn: '2023-09-04T18:00:00.000Z',
    collection: 'Mortality',
    blockId: 1186625328,
    blockName: 'Mortality Wasteland',
    blockImage: '/full/1186625328.png',
    addresses: ['IGQV6KUNPG3VIN7EC5ZJUIWTD3SNANCODTJYV2Q3U7GJZYTK5MZVB4LB3U'],
    assets: [...mortalityAssets],
  },
  borks: {
    id: 8,
    isActive: false,
    stakingOpensOn: '2023-09-12T18:00:00.000Z',
    collection: 'Bork Borks/Secret Shiba Syndicate',
    blockId: 1195948055,
    blockName: 'Bork Arcade',
    blockImage: '/collab-blocks/borks.png',
    addresses: ['BORKICY2IVX4YEFXT4UXQCOKYQLSPSD5TKDAMV3LD3JC3QV7E2XZE65IBE', 'YN3UIE3LYNDU4H2SHIBA56G6RWEVHHLHLGKLBS4EOVZPP3PS6X7GNXAMWY'],
    splitRoyalties: true,
    assets: [...borksAssets, ...shibaAssets],
  },
  bob: {
    id: 9,
    isActive: false,
    stakingOpensOn: '2023-09-12T18:00:00.000Z',
    collection: "Belinda's on the Block",
    blockId: 1195948753,
    blockName: 'Dead BoB Memorial Park',
    blockImage: '/collab-blocks/bob.png',
    addresses: ['U3HE6BSEJJGE2YKSG5PKD22VWIU7WSSJBG3AHOH4BKPHBKT6MAAII3AOEU', 'LB2WYHQOEHWAKBL6GWNWWIC4FJG7ZYSPXTQAMVAWSVZJLJRHEEH2FCESTU'],
    assets: [...bobAssets],
  },
  goanna: {
    id: 10,
    isActive: false,
    stakingOpensOn: '2023-09-19T18:00:00.000Z',
    collection: 'Al Goanna',
    blockId: 1200067279,
    blockName: 'Al Goanna Temple',
    blockImage: '/collab-blocks/algoanna.png',
    addresses: ['D5J7H7PIYKLY2U6A5OFUAC7GQHTHSXXNX65DSD3CJYPBV2MVK6NTNW44CA'],
    kittyOwners: ['PUT22GXLWGZZAWW7DSFCYU76V3Y4PJ23YJANCFSJJOOVD2WWC5F77Q7AEQ', 'ZQVQGUNWAQ5XMMFUG66RW4KCU5NB3RKCVAMDO3WITQLMSOU4FBP3PNIZ4I'],
    assets: [...goannaAssets],
  },
  goannaMutants: {
    id: 11,
    isActive: false,
    stakingOpensOn: '2023-09-19T18:00:00.000Z',
    collection: 'Mutants Vs Zombies: Mutants',
    blockId: 1200071517,
    blockName: 'Mutants Block',
    blockImage: '/collab-blocks/algoanna-mutants.png',
    addresses: ['AW3ZJNEVJKKICLA6JQ6ME64J2BOJORTYLJ7EOOAVF4ROY2QLBCZWLZOHFM'],
    kittyOwners: ['UYLMLS5P3F7G2N4ZMA42ERK5H6RRRZ2XTHTEK4OVIVQA4NKAIJXKOYKLDM', 'R3HZ3BIEFNRIK37LNOOCBCKOFBFJXSESOYO7DCDVUAQUNFD4STNWFOKJMU'],
    assets: [...goannaMutantsAssets],
  },
  goannaZombies: {
    id: 12,
    isActive: false,
    stakingOpensOn: '2023-09-19T18:00:00.000Z',
    collection: 'Mutants Vs Zombies: Zombies',
    blockId: 1200073656,
    blockName: 'Zombies Block',
    blockImage: '/collab-blocks/algoanna-zombies.png',
    addresses: ['2MQZFP7LNZNQDMJO5F3SZDFVEEHOFH6G3NXSLASIJIB7OLDPVLSCX67QZ4'],
    kittyOwners: ['UYLMLS5P3F7G2N4ZMA42ERK5H6RRRZ2XTHTEK4OVIVQA4NKAIJXKOYKLDM'],
    assets: [...goannaZombiesAssets],
  },
  noots: {
    id: 13,
    isActive: false,
    stakingOpensOn: '2023-09-29T18:00:00.000Z',
    collection: 'ALGO NOOTS',
    blockId: 1205905668,
    blockName: 'NOOTS Lake',
    blockImage: '/collab-blocks/noots.png',
    addresses: ['E4LOOSRXEFLJOOAZRR3YYZB3QN7WL42UAQ2PWG7P6OWMWSNSSZ2TX72RUQ'],
    assets: [...nootsAssets],
  },
  akita: {
    id: 14,
    isActive: false,
    stakingOpensOn: '2023-09-29T18:00:00.000Z',
    collection: 'Akita Kennel Club',
    blockId: 1205905872,
    blockName: 'Akita Dog Park',
    blockImage: '/collab-blocks/akita.png',
    addresses: ['AKCTRDK4OWNWHTPH4XPKLNWNLZ333VE35SKQ4FGQK3ZJA4FIHCLTRG3PFI'],
    assets: [...akitaAssets],
  },
  mentalMarvins: {
    id: 15,
    isActive: false,
    stakingOpensOn: '2023-10-09T18:00:00.000Z',
    collection: 'Mental Marvin',
    blockId: 1215951339,
    blockName: 'Marvin Attacks!',
    blockImage: '/collab-blocks/mental-marvin.png',
    addresses: ['47KPTVPRIIKKGEVSLTTOTK42ZK7FNMBSLMGXCAXENEWDDDVYJDERINJXMA'],
    assets: [...mentalMarvinsAssets],
  },
  knitheads: {
    id: 16,
    isActive: false,
    stakingOpensOn: '2023-10-09T18:00:00.000Z',
    collection: 'KNITheads',
    blockId: 1215952237,
    blockName: 'Vroooom Track',
    blockImage: '/collab-blocks/knith3ads.png',
    addresses: ['45LDVA6A44QD2PNWNAPGGDQESXNOY36HJC6UZXZNMIAYLXUYD4DGRAMNNA', 'PIXYN3736RN7XS7ZA354R33RTDUTPRAZ2YMGU3V72I3EVDY62O3TDK43X4'],
    assets: [...knitheadsAssets],
  },
  littleRoyalsLegendary: {
    id: 'L2',
    isActive: false,
    stakingOpensOn: '2023-09-04T18:00:00.000Z',
    collection: 'Little Royals',
    blockId: 1213224198,
    blockName: 'Little Royals Treasury',
    blockImage: '/minified/1213224198.gif',
    addresses: ['GL6QISE6DYSBJGCZSK6AFTJBHYM2E6DTH47PIDDA4SL7WWHN4U7MJDYCGY'],
    assets: [...littleRoyalsAssets],
  },
  littleRoyals: {
    id: 17,
    isActive: false,
    stakingOpensOn: '2023-10-18T18:00:00.000Z',
    collection: 'Little Royals',
    blockId: 1222782875,
    blockName: 'Little Royals Castle',
    blockImage: '/collab-blocks/little-royals-castle.png',
    addresses: ['GL6QISE6DYSBJGCZSK6AFTJBHYM2E6DTH47PIDDA4SL7WWHN4U7MJDYCGY'],
    assets: [...littleRoyalsAssets],
  },
  crazyLabs: {
    id: 18,
    isActive: false,
    stakingOpensOn: '2023-11-01T18:00:00.000Z',
    collection: 'Crazy Embryo Club/Crazy Guys/Crazy Kids/Crazy Cops',
    blockId: 1234961202,
    blockName: 'Crazy Labs',
    blockImage: '/collab-blocks/crazy-labs.png',
    addresses: [
      '6X7OHCZLF4JUCMGMBAZZ7BENCUDRFEGI5MSYJBL6CWNNQDMOYMMUSB55BY',
      'DXZQRGDQDD3WFEDJA2X2IUFU3LER7ZXWKPA54E4RFBO2HQTQDFXIIOO4AA',
      'VENKYO6K7ZDAU6LCR2P4RPCDAXMXFVHBYCNDBTYVNTADI4ITTI6LWLNP5I',
      'EDJQYPKXF2Y2STMX4T4O3O2VVNDQLRQJP3RKWXZJGIROIBRN3UBZMWRA2E',
    ],
    assets: [...crazyAssets],
  },
  daruma: {
    id: 19,
    isActive: false,
    stakingOpensOn: '2023-11-01T18:00:00.000Z',
    collection: 'AlgoDaruma',
    blockId: 1234961801,
    blockName: 'Daruma Temple',
    blockImage: '/collab-blocks/daruma-temple.png',
    addresses: ['PJZZK5XLDUGMNBKQIEGFERO6TLSB4M3ZC6G32WPOLDIOCPUTVUMH4S52GE'],
    assets: [...darumaAssets],
  },
  alex: {
    id: 20,
    isActive: false,
    stakingOpensOn: '2023-11-12T18:00:00.000Z',
    collection: 'Alex',
    blockId: 1246271291,
    blockName: "Alex's Den",
    blockImage: '/collab-blocks/alexs-den.png',
    addresses: ['KVC4EZQTW7M3RQWJZZXVDJ7W6THDKK7SEOKAYYSXVBN3U6HD3KKNZ2QL4E'],
    assets: [...alexAssets],
  },
  raven: {
    id: 21,
    isActive: false,
    stakingOpensOn: '2023-11-12T18:00:00.000Z',
    collection: 'R4V3N',
    blockId: 1246273768,
    blockName: 'R4V3N Manufacturing Lab',
    blockImage: '/collab-blocks/r4v3n-manufacturing-lab.png',
    addresses: ['R4V3NQKUDJKQWV74JZWEVDNJ6HMHHZK253FGMRQHAE6RA5MAKXBCYVVLQE'],
    assets: [...ravenAssets],
  },
  heepow: {
    id: 22,
    isActive: false,
    stakingOpensOn: '2023-11-22T18:00:00.000Z',
    collection: 'Heepow/BRATS',
    blockId: 1256934842,
    blockName: 'Heepow Watering Hole',
    blockImage: '/collab-blocks/heepow.png',
    addresses: ['7WE3HVHVLNTBWHAJX7RZQPY5YW3NS4PVUF3DFP6EALRDFEYE7A4ME2EBDI', 'BRATZLXKQZFNTONK7UNNTXVONWTLS6WUZFMFM4ICGAIK5H6LBLYTHYRFL4'],
    assets: [...heepowAssets],
  },
  dragon: {
    id: 23,
    isActive: false,
    stakingOpensOn: '2023-12-06T18:00:00.000Z',
    collection: 'DragonFi Moon Dragons',
    blockId: 1272214008,
    blockName: 'Mt. Moon Dragon',
    blockImage: '/collab-blocks/moon-dragons.png',
    addresses: ['MUEGNKSGQJGPQC44PNEQHJAUAUR45HE7ZJDCB3RFE5RMBIN3X6D3BGI3H4'],
    assets: [...dragonAssets],
  },
  meowlgo: {
    id: 24,
    isActive: false,
    stakingOpensOn: '2024-01-04T18:00:00.000Z',
    collection: 'Meowlgo',
    blockId: 1391613207,
    blockName: 'Meowlgo Fish Market',
    blockImage: '/collab-blocks/meowlgo.png',
    addresses: ['EOFSFVNKYKVY7OEF4QSCJZZ2HSPIAHR3D2QLFVWH2NZHKZOEUHU3MF3CIM', 'SD3CDHCCDTZJWBZC5WTBCARG4QWLDBNSA7PCASLYJHAK7LKZLXFY4ZEAGY'],
    assets: [...meowlgoAssets],
  },
  cyber: {
    id: 25,
    isActive: false,
    stakingOpensOn: '2024-01-23T19:00:00.000Z',
    collection: 'Cyber Skulls',
    blockId: 1433051123,
    blockName: 'Cyber City',
    blockImage: '/collab-blocks/cyber.webp',
    addresses: ['ANT36DHMAAUWZ3LPTRE7WNPQILWKJN24F66VQA22NIJ5D5CQDBB5333ZEU'],
    assets: [...cyberAssets],
  },
  hans: {
    id: 26,
    isActive: false,
    stakingOpensOn: '2024-02-05T19:00:00.000Z',
    collection: 'FUMS/Algo Hands',
    blockId: 1502814028,
    blockName: 'Hans Food Truck',
    blockImage: '/collab-blocks/hans.png',
    addresses: ['3WT7EFS2PZSA57IQT6BNBKMYENZZ5XBWMBWN7LF2OIUUWGVJPYU7YWCIP4', '4CMTVUMY3UJ23XABX5IVBYYGPTTE5WOZCCIRYRRGSEXVJOD3DRM6CUDMSU'],
    assets: [...hansAssets],
  },
  coffee: {
    id: 27,
    isActive: false,
    stakingOpensOn: '2024-02-17T19:00:00.000Z',
    collection: 'CoffeeBits/CoffeeBits Bath Club',
    blockId: 1593685162,
    blockName: 'Coffee Bath',
    blockImage: '/collab-blocks/coffee.png',
    addresses: ['CAFEPXFFD3NYSCYWAMZQ75BOLZYACDW37NIXQYJVMHRMRZILAQOVM37BEY', 'BATHK3EDDYAYMYZLOMOAH2RLWZI5PS5NJ25N3JUON7A3U47SD6CH5JXWRI'],
    assets: [...coffeeAssets],
  },
  worms: {
    id: 28,
    isActive: false,
    stakingOpensOn: '2024-03-24T19:00:00.000Z',
    collection: 'Worms',
    blockId: 1708657386,
    blockName: 'Wormhole',
    blockImage: '/collab-blocks/wormhole.png',
    addresses: ['7DD3VD5U6OT7P73LRWOZP4547J3LM2X7AWA7BLYNEBYVLPSFKM4BOV42GQ'],
    assets: [...wormAssets],
  },
  blops: {
    id: 29,
    isActive: false,
    stakingOpensOn: '2024-04-13T19:00:00.000Z',
    collection: 'AlgOctopus/BLOPS',
    blockId: 1761001379,
    blockName: 'BLOP Pods',
    blockImage: '/collab-blocks/blops.webp',
    addresses: ['U4WMQCXM7655FIJS6AYBKC6A3R74X2XZ3T4SPJV52BQVJ5WF7HTYKGQQ74', 'BLOPKZRAUF5HNLKRYOX4WDWF7SKETVH3B5TYL2ESIOJOVUKCCCKV55MXIQ'],
    assets: [...blopAssets],
  },
  skuli: {
    id: 30,
    isActive: false,
    stakingOpensOn: '2024-07-13T19:00:00.000Z',
    collection: 'Skuli Frens/Skuli Gals',
    blockId: 2179937285,
    blockName: `Skuli Bryant's BBQ`,
    blockImage: '/collab-blocks/skuli-bryants.png',
    addresses: ['OEHVRBA52DJ6BJGJEJZIAI573X72QV3RVFJKJFKMCWFB7QTK7CQKMCGPSA', '37MDVBLGV3ZKNQPMYIMLQPMJEYXVAI3XMJ3KGYHLXZ5MSZI5HSDW5EWSAI'],
    assets: [...skuliAssets],
  },
  wizards: {
    id: 31,
    isActive: false,
    stakingOpensOn: '2024-08-26T19:00:00.000Z',
    collection: 'Wizards of Algorand',
    blockId: 2273867599,
    blockName: `Wizard's Hut`,
    blockImage: '/collab-blocks/wizards.png',
    addresses: ['74FPP2IFJQCSMAJTHV65QO4P65RMINC3GYJYGZHALCO2QVPIFRKK3V7JNA'],
    assets: [...wizardAssets],
  },
  gully: {
    id: 32,
    isActive: false,
    stakingOpensOn: '2024-09-09T19:00:00.000Z',
    collection: 'GULLY',
    blockId: 2300159468,
    blockName: `Shitty Gully Lagoon`,
    blockImage: '/collab-blocks/gully.png',
    addresses: ['ZK7CZT7NZJT6G6HWTZ5VD5SLUSTJSVFA2DW2FMLFYZKM7SAFENR7QZE6KE'],
    assets: [...gullyAssets],
  },
  billy: {
    id: 33,
    isActive: true,
    stakingOpensOn: '2024-09-23T19:00:00.000Z',
    collection: 'Billy Big Beak',
    blockId: 2328438206,
    blockName: `Billy's Oasis`,
    blockImage: '/collab-blocks/billy.png',
    addresses: ['MVIF2VQKPHC4EDRJW6YA7ZMP5UZDWYXAXUGTJ5EJFDFZBAWEB547HHGIT4', '3HOF4YRB7IMFUPOSKHCTLJ44JXCBJ7BKDALXXSQMKQJONKKICO2AVX4XKM'],
    assets: [...billyAssets],
  },
};

export default collabBlocks;
