import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, getFirestore, query, runTransaction, setDoc, updateDoc, where } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import chunk from 'lodash/chunk';
import config from './config';
import calculateCityRating from './utils/calculateCityRating';
import calculateMaxPopulation from './utils/calculateMaxPopulation';

dayjs.extend(timezone);
dayjs.extend(advanced);
dayjs.tz.setDefault('America/New_York');

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

const updateRaffleProps = (raffle) => {
  raffle.ticketCost = parseInt(raffle.ticketCost);
  raffle.assetId = parseInt(raffle.assetId);

  if (raffle.maxEntries) {
    raffle.maxEntries = parseInt(raffle.maxEntries);
  } else {
    delete raffle.maxEntries;
  }

  if (raffle.totalTickets) {
    raffle.totalTickets = parseInt(raffle.totalTickets);
  } else {
    delete raffle.totalTickets;
  }

  if (raffle.startDate) {
    raffle.startDate = new Date(raffle.startDate);
  }

  if (raffle.endDate) {
    raffle.endDate = new Date(raffle.endDate);
  }

  return raffle;
};

export const createRaffle = async (raffle) => {
  // get raffles
  const rafflesRef = collection(db, 'raffles');
  const rafflesQuery = query(rafflesRef);
  const rafflesSnapshot = await getDocs(rafflesQuery);
  const raffles = rafflesSnapshot.docs.map((doc) => doc.data()).sort((a, b) => parseInt(b.id) - parseInt(a.id));
  const [mostRecentRaffle] = raffles;
  const nextId = mostRecentRaffle ? parseInt(mostRecentRaffle.id) + 1 : 1;

  raffle = updateRaffleProps(raffle);

  await setDoc(
    doc(db, 'raffles', nextId.toString()),
    {
      ...raffle,
      id: nextId.toString(),
      active: true,
    },
    { merge: true }
  );
};

export const editRaffle = async (raffleId, data) => {
  const raffle = updateRaffleProps(data);

  await updateDoc(doc(db, 'raffles', raffleId), {
    ...raffle,
  });
};

export const getLotteryStats = async (address) => {
  const lotteryStatsRef = collection(db, 'lottery_stats');
  const lotteryStatsQuery = query(lotteryStatsRef, where('address', '==', address));
  const lotteryStatsSnapshot = await getDocs(lotteryStatsQuery);
  const lotteryStats = lotteryStatsSnapshot.docs.map((doc) => doc.data());
  return lotteryStats[0];
};

export const getVendingMachineCooldown = async (address) => {
  // get doc with id of address
  const cooldownRef = doc(db, process.env.NEXT_PUBLIC_VENDING_COOLDOWN_COLLECTION, address);
  const cooldownDoc = await getDoc(cooldownRef);

  if (cooldownDoc.exists()) {
    return cooldownDoc.data();
  }

  return null;
};

export const getCooldownDetails = async (address) => {
  if (!address) return { purchaseCount: 0, nextPurchaseAt: null };

  const resp = await getVendingMachineCooldown(address);
  if (resp) {
    if (resp.nextPurchaseAt) {
      const cooldownDate = dayjs(resp.nextPurchaseAt.toDate());
      const isAfterCooldown = dayjs().isAfter(cooldownDate);

      if (!isAfterCooldown) {
        return { purchaseCount: resp.count, nextPurchaseAt: resp.nextPurchaseAt.toDate() };
      }
    } else {
      // handle old entries that don't have nextPurchaseAt
      if (resp.count === 2) {
        return { purchaseCount: 0, nextPurchaseAt: null };

        // show 1 purchase left
      } else {
        return { purchaseCount: resp.count, nextPurchaseAt: null };
      }
    }
  }

  return { purchaseCount: 0, nextPurchaseAt: null };
};

export const updateKittyLikes = async ({ kittyId, address }) => {
  if (!address) {
    throw 'No address provided';
  }

  // create a transaction that will update the kitty_bio collection doc with the kittyId
  // and update the likes array with the address
  const kittyBioRef = doc(db, 'kitty_bios', kittyId.toString());

  await runTransaction(db, async (transaction) => {
    const [kittyBioDoc, kittiesWithBios] = await Promise.all([transaction.get(kittyBioRef), getKittiesCache()]);
    const kittyIndex = kittiesWithBios.findIndex((kitty) => kitty.id === kittyId);

    if (!kittyBioDoc.exists()) {
      const newBio = { likes: [address], assetId: kittyId };
      // create document
      transaction.set(kittyBioRef, newBio);

      // update cache
      if (kittyIndex !== -1) {
        kittiesWithBios[kittyIndex].bio = { ...kittiesWithBios[kittyIndex].bio, ...newBio };
        transaction.update(doc(db, 'cache', 'kitties_with_bios'), { kitties: kittiesWithBios });
      }
      return;
    }

    const kittyBio = kittyBioDoc.data();

    if (!kittyBio.likes) {
      kittyBio.likes = [];
    }

    if (!kittyBio.assetId) {
      kittyBio.assetId = kittyId;
    }

    // if kitty
    if (kittyBio.likes.includes(address)) {
      kittyBio.likes = kittyBio.likes.filter((like) => like !== address);
    } else {
      kittyBio.likes.push(address);
    }

    transaction.update(kittyBioRef, kittyBio);

    // find kitty in kittiesWithBiosDoc
    if (kittyIndex !== -1) {
      kittiesWithBios[kittyIndex].bio = { ...kittiesWithBios[kittyIndex].bio, ...kittyBio };

      const partSize = Math.ceil(kittiesWithBios.length / 5);
      const part1 = kittiesWithBios.slice(0, partSize);
      const part2 = kittiesWithBios.slice(partSize, partSize * 2);
      const part3 = kittiesWithBios.slice(partSize * 2, partSize * 3);
      const part4 = kittiesWithBios.slice(partSize * 3, partSize * 4);
      const part5 = kittiesWithBios.slice(partSize * 4);

      transaction.update(doc(db, 'cache', 'kitties_with_bios_1'), { kitties: part1 });
      transaction.update(doc(db, 'cache', 'kitties_with_bios_2'), { kitties: part2 });
      transaction.update(doc(db, 'cache', 'kitties_with_bios_3'), { kitties: part3 });
      transaction.update(doc(db, 'cache', 'kitties_with_bios_4'), { kitties: part4 });
      transaction.update(doc(db, 'cache', 'kitties_with_bios_5'), { kitties: part5 });
    }
  });
};

export const getShittyUser = async ({ address }) => {
  if (!address) {
    throw new Error('Missing address');
  }

  const ref = doc(db, 'users', address);
  const theDoc = await getDoc(ref);
  const data = theDoc.data();
  return data;
};

export const getUserAchievements = async (address) => {
  const usersRef = doc(db, 'users', address);
  const achievementsRef = collection(usersRef, 'achievements');
  const achievementsSnapshot = await getDocs(achievementsRef);
  const achievements = achievementsSnapshot.docs.map((doc) => doc.data());
  return achievements;
};

// * only used in this file
export const getKittiesCache = async () => {
  const [snapshot_1, snapshot_2, snapshot_3, snapshot_4, snapshot_5] = await Promise.all([
    getDoc(doc(db, 'cache', 'kitties_with_bios_1')),
    getDoc(doc(db, 'cache', 'kitties_with_bios_2')),
    getDoc(doc(db, 'cache', 'kitties_with_bios_3')),
    getDoc(doc(db, 'cache', 'kitties_with_bios_4')),
    getDoc(doc(db, 'cache', 'kitties_with_bios_5')),
  ]);
  const data_1 = snapshot_1.data();
  const data_2 = snapshot_2.data();
  const data_3 = snapshot_3.data();
  const data_4 = snapshot_4.data();
  const data_5 = snapshot_5.data();
  return [...data_1.kitties, ...data_2.kitties, ...data_3.kitties, ...data_4.kitties, ...data_5.kitties];
};

export const getCity = async (cityId, cities) => {
  const docRef = doc(db, config.citiesCollectionName, cityId.toString());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const theCity = docSnap.data();
    theCity.currentPopulation = theCity.citizens?.length || 0;
    theCity.rarityScore = calculateCityRating(theCity, cities);
    theCity.maxPopulation = calculateMaxPopulation({ city: theCity });

    return theCity;
  } else {
    return null;
  }
};

export const getCollabBlockStakingEntry = async (collab, address) => {
  const docRef = doc(db, config.collabBlockStakingCollectionName, `${collab}_${address}`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
};

// Citizens
export const checkForKittyInCity = async (kittyId) => {
  const querySnapshot = await getDocs(
    query(collection(db, config.citiesCollectionName), where('citizens', 'array-contains', kittyId), where('city', '==', cityId))
  );
  const city = querySnapshot.docs[0]?.data();
  return city;
};

export const getCitizenDetails = async (kitties, cityId) => {
  if (!kitties.length) return [];
  const chunkedKitties = chunk(kitties, 10);
  const queryPromises = chunkedKitties.map((chunk) =>
    getDocs(query(collection(db, config.citizensCollectionName), where('kitty', 'in', chunk), where('city', '==', cityId)))
  );
  const querySnapshots = await Promise.all(queryPromises);

  const details = querySnapshots.flatMap((querySnapshot) => querySnapshot.docs).map((doc) => doc.data());
  return details;
};
